import { Controller } from "stimulus";
import BulmaTagsInput from "@creativebulma/bulma-tagsinput";

// This controller handles all tagging logic
// It using BulamaTagsInput
// It listens for events
// https://github.com/CreativeBulma/bulma-tagsinput/blob/master/src/docs/data/events.json

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const predefinedTags = [
      "environment:production",
      "environment:staging",
      "environment:test",
    ];
    // console.log("hello from tags controller");

    // FIXME: this is probably not the right way to do this

    BulmaTagsInput.attach(this.inputTarget, {
      source: [
        ...new Set([
          ...this.data.get("initial-tags").split(","),
          ...predefinedTags,
        ]),
      ],
      tagClass: "is-round is-info is-light",
    });

    this.add = this.add.bind(this);
    this.remove = this.remove.bind(this);

    this.currentTags = this.data.get("initial-tags");
  }

  updateTags(prevPrev, newTags) {
    let action = prevPrev.length > newTags.length ? "remove" : "add";

    prevPrev = prevPrev.split(",");
    newTags = newTags.split(",");

    if (action === "add") {
      let toAdd = newTags.find((item) => !prevPrev.includes(item));
      this.add({ item: toAdd });
    }

    if (action === "remove") {
      let toRemove = prevPrev.find((item) => !newTags.includes(item));
      this.remove({ item: toRemove });
    }

    // update the currentTags
    this.currentTags = newTags.join(",");
  }

  edit(event) {
    if (event instanceof CustomEvent) {
      // console.log(event);
      this.updateTags(this.currentTags, event.target.value);
    }
  }

  add(data) {
    // console.log("add", data);

    const payload = { tag: data["item"] };

    fetch(this.addUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrf,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  remove(data) {
    console.log("remove", data);
    const payload = { tag: data["item"] };

    fetch(this.removeUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.csrf,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  get input() {
    return this.inputTarget.value;
  }

  get addUrl() {
    return this.data.get("add-url");
  }

  get removeUrl() {
    return this.data.get("remove-url");
  }

  get csrf() {
    return document
      .querySelector("meta[name='csrf-token']")
      .getAttribute("content");
  }
}
